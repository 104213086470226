import React, { useCallback, useState } from "react";
import { useHistory } from "react-router";
import { getDriverPaymentResponsibilityLabelByPayerType } from "../../utils/driver.utils";
import { BlockText } from "../block/Block";
import MessageButton from "../button/MessageButton";
import Card from "../card/Card";
import Row from "../custom/Row";
import VehiclePlaceholderIcon from "../icon/icons/VehiclePlaceholderIcon";

import ClipboardWithCopyIcon from "components/clipboard/ClipboardWithCopyIcon";
import { BlackBodyText100 } from "../text/Text";
import ChargingSessionsDetailsPageCardBlock from "./ChargingSessionsDetailsPageCardBlock";
import { PayerType } from "../../types/payment.types";
import HoverUnderlinedTextButton from "../button/HoverUnderlinedTextButton";
import AlertConfirmDialog from "../notification/AlertConfirmDialog";
import { updateChargingPayerToOrgAsync } from "../../services/charging.service";
import { ChargingPaymentState, ChargingState, ChargingUpdateCommands } from "../../types/charging-sessions.types";
import { useAlertManager } from "../../hooks/alert.hooks";
import { handleApiErrorResponse } from "../../utils";
import InfoBadge from "../badge/InfoBadge";
import CancelButton from "../button/CancelButton";
import PrimaryButton from "../button/PrimaryButton";


export default function DetailsPageUserCard({ chargingSessionDetails, refetch }) {
  const [isPayerConfirmationOpen, setIsPayerConfirmationOpen] = useState(false);
  const { user, vehicle } = chargingSessionDetails;

  const { handleOpenSuccessAlert, handleOpenErrorAlert } = useAlertManager();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const history = useHistory();


  const handleViewClicked = useCallback(() => {
    if (!user?.id) {
      return;
    }

    history.push(`/drivers/${user?.id}`);
  }, [user?.id, history]);


  const handleUpdatePayerDialogConfirmed = useCallback(async () => {
    try {
      setIsSubmitting(true);
      await updateChargingPayerToOrgAsync(chargingSessionDetails.id, {
        command: ChargingUpdateCommands.UPDATE_AS_ORGANIZATION_PAYS
      });

      refetch();
      handleOpenSuccessAlert("Payer is updated successfully.");
    } catch (e) {
      console.error(e);
      handleOpenErrorAlert(handleApiErrorResponse(e));
    } finally {
      setIsSubmitting(false);
    }
  }, [chargingSessionDetails.id, handleOpenErrorAlert, handleOpenSuccessAlert, refetch]);


  if (!vehicle && !user) {
    return <></>;
  }

  const isPayerDriver = chargingSessionDetails.payer === PayerType.DRIVER;
  const isNotPaid = chargingSessionDetails.paymentState !== ChargingPaymentState.PAID;
  const isStateDone = chargingSessionDetails.state = ChargingState.COMPLETED;

  const isPayerToOrgVisible = isPayerDriver && isNotPaid && isStateDone;


  return (
    <Card>
      {vehicle && (
        <>
          <Row className="align-center mb-1">
            <div className="w-48px h-48px mr-1">
              <VehiclePlaceholderIcon />
            </div>
            <BlockText className={"m-0"}>{vehicle?.name}</BlockText>
          </Row>

          <ChargingSessionsDetailsPageCardBlock
            leftLabel="Vehicle"
            leftText={vehicle?.carBrand}
            rightLabel="Model"
            rightText={vehicle?.carModel}
          />
        </>
      )}

      {user && (
        <>
          <AlertConfirmDialog
            isDialogOpen={isPayerConfirmationOpen}
            title="Are you sure you want to change the payment responsibility for this session? "
            descriptionOverride={<InfoBadge
              text={"Switching to \"Org Pays\" will cancel the driver's invoice and the session will be charged to the organization.\n" +
                "\n" +
                "This change will only apply to this session. To adjust the driver's payment responsibility for future sessions, please visit the driver detail page."} />}
            toggleIsDialogOpen={() => setIsPayerConfirmationOpen(false)}
            overrideButtons={
              <>
                <div className="mr-2">
                  <CancelButton onClick={() => setIsPayerConfirmationOpen(false)} />
                </div>

                <PrimaryButton isLoading={isSubmitting} text={"Confirm"} onClick={handleUpdatePayerDialogConfirmed} />
              </>
            }
          />
          <ChargingSessionsDetailsPageCardBlock
            isBorderTopVisible={!!vehicle}
            leftLabel="Payment Responsibility"
            leftTextOverwrite={<Row>
              <BlackBodyText100 className={"mr-2 word-wrap-normal w-max-100 text-truncate"}>
                {getDriverPaymentResponsibilityLabelByPayerType(chargingSessionDetails.payer)}
              </BlackBodyText100>
              {isPayerToOrgVisible && (
                <HoverUnderlinedTextButton text={"Switch to Org pays"}
                                           onClick={() => setIsPayerConfirmationOpen(true)} />)}
            </Row>
            }
          />
          <ChargingSessionsDetailsPageCardBlock
            leftLabel="Driver name"
            leftTextOverwrite={
              <div onClick={handleViewClicked} className="clickable">
                <BlackBodyText100>
                  {user?.firstName} {user?.lastName}
                </BlackBodyText100>
              </div>
            }
            rightBeforeText={
              <>
                <MessageButton userId={user?.id} height={36} />
              </>
            }
          />

        </>
      )}

      {vehicle && (
        <ChargingSessionsDetailsPageCardBlock
          leftLabel="License Plate"
          leftText={vehicle?.plateNumber}
          rightLabel={"VIN"}
          rightTextOverwrite={<ClipboardWithCopyIcon TextComponent={BlackBodyText100} text={vehicle?.vin} />}
        />
      )}
    </Card>
  );
}

/**
 * onClick={() => history.push(`/edit-vehicle/${row.id}`)}
 */
