import { useHistory } from "react-router";
import { getLocalFormattedDateForTables } from "utils/date.utils";
import ChargingSessionsSourceInfoTooltipContent
  from "../components/charging-sessions-components/ChargingSessionsSourceInfoTooltipContent";

import { Icon } from "../components/Component";
import Row from "../components/custom/Row";
import InfoIcon from "../components/icon/icons/InfoIcon";

import { CardTooltip } from "../components/tooltip/MuiTooltip";
import {
  convertSecondsToMinutesAndHours,
  getChargerInfo,
  getChargerTypeIcon,
  getFormattedNumberForEnergy,
  getWhoPaysDisplayTextByPayerType
} from "../utils/charging.utils";
import UnderlinedTextButton from "../components/button/UnderlinedTextButton";
import { TextButtonMode } from "../components/button/TextButton";
import PaidStatusBadge from "../components/badge/PaidStatusBadge";
import { getFormattedCostByProvider } from "../utils/format.utils";
import ChargingStateBadge from "../components/badge/ChargingStateBadge";

export function useChargingSessionCommonTableColumns() {
  const history = useHistory();
  const commonColumns = {
    vehicle: {
      minWidth: "150px",
      grow: 2,
      name: "Vehicle",
      selector: (row) => <p title={row.vehicle?.name}>{row.vehicle?.name ?? "-"}</p> ?? "-"
    },
    driver: {
      minWidth: "170px",
      grow: 1.5,
      name: "Driver Name",
      selector: (row) => {
        const driverName = `${row?.user?.firstName ?? "-"}  ${row?.user?.lastName ?? ""} `;

        return (
          <Row className="center w-100 " onClick={() => history.push(`/drivers/${row?.user?.id}`)}>
            {driverName}
          </Row>
        );
      }
    },
    state: {
      minWidth: "100px",
      grow: 1,
      name: "State",
      selector: (row) => (
        <ChargingStateBadge state={row.state} />
      )
    },
    chargerName: {
      minWidth: "200px",
      grow: 2,
      name: "Charger Name",
      selector: (row) => row.chargerName
    },
    stripeInvoiceId: (onClick: (invoiceId?: string) => {}, isLoading: boolean) => {
      return {
        style: {
          pointerEvents: "all",
          zIndex: 99
        },
        minWidth: "100px",
        grow: 1,
        name: "Invoice",
        selector: (row) =>
          row.invoiceId ? (
            <UnderlinedTextButton
              text={"See invoice"}
              className={"priority-link"}
              mode={TextButtonMode.PRIMARY}
              textClassName={`${isLoading && "cursor-progress"}`}
              onClick={() => onClick(row.invoiceId)} />
          ) : (
            "-"
          )
      };
    },
    type: {
      minWidth: "100px",
      grow: 1,
      name: "Type",
      cell: (row) =>
        row?.type ? (
          <p>
            {getChargerTypeIcon(row.type)} <span className="ml-1"> {row.type} </span>
          </p>
        ) : (
          "-"
        )
    },
    energy: {
      grow: 1,
      minWidth: "120px",
      name: "kWh",
      selector: (row) => <>{getFormattedNumberForEnergy(row?.energyDelivered)} kWh </>
    },
    cost: {
      grow: 1,
      minWidth: "120px",
      name: "Cost",
      selector: (row) => <>{getFormattedCostByProvider(row?.cost, row.provider)} </>
    },
    payStat: {
      grow: 1,
      minWidth: "120px",
      name: "PayStat",
      selector: (row) => (
        <PaidStatusBadge status={row.paymentState} />
      )
    },
    source: {
      grow: 1,
      minWidth: "110px",
      name: (
        <Row>
          <span className="mr-1">Source</span>
          <CardTooltip title={<ChargingSessionsSourceInfoTooltipContent />} placement="top">
            <div>
              <InfoIcon fill="#CBD5E1" />
            </div>
          </CardTooltip>
        </Row>
      ),
      selector: (row) => <div className="w-65px center">{getChargerInfo(row.provider)?.logo}</div>
    },
    whoPays: {
      grow: 1,
      minWidth: "120px",
      name: "Who Pays",
      selector: (row) => <>{getWhoPaysDisplayTextByPayerType(row?.payer)} </>
    },
    duration: {
      grow: 1,
      minWidth: "150px",
      name: "Duration",
      selector: (row) => <>{convertSecondsToMinutesAndHours(row?.duration)} </>
    },
    date: {
      grow: 1,
      minWidth: "200px",
      name: "Date",
      selector: (row) => <>{getLocalFormattedDateForTables(row?.createdAt)} </>
    },
    action: {
      width: "20px",
      name: "",
      cell: (row) => (
        <Row className="center w-100 pr-4" onClick={() => history.push(`/charging-sessions/${row?.id}`)}>
          <Icon style={{ pointerEvents: "none" }} name="arrow-long-right" />
        </Row>
      )
    }
  };

  return commonColumns;
}
