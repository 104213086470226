export function generateApiEndpoint(url) {
  return `${process.env.REACT_APP_API_URL}/admin/${url}`;
}

//
export function generateApiEndpointWithoutAdmin(url) {
  return `${process.env.REACT_APP_API_URL}/${url}`;
}

export const handleApiErrorResponse = (err) => {
  let errorMessage = "";

  if (err.errors && Array.isArray(err.errors)) {
    for (const error of err.errors) {
      if (error.keyword === "required") {
        errorMessage = error.message;
        break;
      }
    }
  } else {
    errorMessage = err?.message ?? err.response?.data?.message ??  "Something went wrong";
  }

  return errorMessage;
};


export const handleAwsErrorResponse = (err) => {
  let errorMessage = "";

  if (err.errors && Array.isArray(err.errors)) {
    for (const error of err.errors) {
      if (error.keyword === "required") {
        errorMessage = error.message;
        break;
      }
    }
  } else {
    errorMessage = err.response?.data?.message ?? "Something went wrong";
  }

  return errorMessage;
};